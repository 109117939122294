import {GatsbyBrowser} from 'gatsby';

import './src/styles/global.css';

export {default as wrapRootElement} from './src/utility/wrap-root-element';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({routerProps}) => {
  const {hash} = routerProps.location;
  if (hash.length === 0) return true;

  setTimeout(() => {
    const element = document.querySelector(hash);
    element.scrollIntoView();
  }, 10);
};

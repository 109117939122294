exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-for-promoter-tsx": () => import("./../../../src/pages/for-promoter.tsx" /* webpackChunkName: "component---src-pages-for-promoter-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-promotor-error-tsx": () => import("./../../../src/pages/promotor-error.tsx" /* webpackChunkName: "component---src-pages-promotor-error-tsx" */),
  "component---src-pages-promotor-success-tsx": () => import("./../../../src/pages/promotor-success.tsx" /* webpackChunkName: "component---src-pages-promotor-success-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-templates-regulations-tsx": () => import("./../../../src/templates/regulations.tsx" /* webpackChunkName: "component---src-templates-regulations-tsx" */)
}

